import '../styles/main.scss'
import * as React from 'react'
import {PageProps, graphql, Link} from 'gatsby'
import {Layout, SiteConfigData} from '../components/Layout'
import {ContentfulBlogPost, ContentfulBlogPostData} from '../components/ContentfulBlogPost'
import {SEO} from '../components/SEO'
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import cx from 'classnames'

export const query = graphql`
  query BlogPost(
    $locale: String!
    $blogPostId: String!
    $pageId: String!
    $contentfulId: String!
    $previousBlogPostId: String
    $nextBlogPostId: String
  ) {
    blogPost: contentfulBlogPost(id: {eq: $blogPostId}) {
      ...ContentfulBlogPost
    }
    previousBlogPost: contentfulBlogPost(id: {eq: $previousBlogPostId}) {
      id
      published
      slug
      title
    }
    nextBlogPost: contentfulBlogPost(id: {eq: $nextBlogPostId}) {
      id
      published
      slug
      title
    }
    page: contentfulPage(id: {eq: $pageId}) {
      path
    }
    siteConfig: contentfulSiteConfig(
      node_locale: {eq: $locale}
      contentful_id: {eq: "34xwyJJdatr58oSNFH6VjS"}
    ) {
      ...SiteConfig
    }
    alternatePage: sitePage(context: {contentfulId: {eq: $contentfulId}, locale: {ne: $locale}}) {
      path
      context {
        locale
      }
    }
  }
`

interface QueryData {
  siteConfig: SiteConfigData
  blogPost: ContentfulBlogPostData
  page: {path: string}
  sitePage: {path: string}
  alternatePage: {path: string; context: {locale: string}}
  previousBlogPost: {
    id: string
    published: string
    slug: string
    title: string
  }
  nextBlogPost: {
    id: string
    published: string
    slug: string
    title: string
  }
}

interface PageContext {
  isAmp?: boolean
  locale: string
  canonicalPath: string
  baseBlogPath: string
}

const BlogPost: React.FC<PageProps<QueryData, PageContext>> = ({data, pageContext}) => {
  const {canonicalPath, baseBlogPath, locale} = pageContext
  const {siteConfig, blogPost, previousBlogPost, nextBlogPost, alternatePage} = data
  const {title, description, image, published, body, author} = blogPost

  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'BlogPosting',
    inLanguage: locale,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://www.elsa.science${canonicalPath}`,
    },
    headline: title,
    about: {
      '@type': 'MedicalCondition',
      name: 'Rheumatoid Arthritis',
      code: {
        '@type': 'MedicalCode',
        code: '714.0',
        codingSystem: 'ICD-9',
      },
    },
    publisher: {
      '@type': 'Organization',
      name: 'Elsa Science AB',
      logo: {
        '@type': 'ImageObject',
        url: `https://www.elsa.science/blogpost-logo.png`,
        width: 600,
        height: 60,
      },
    },
    ...(author
      ? {
          author: {
            '@type': 'Person',
            name: author,
          },
        }
      : {
          author: {
            '@type': 'Organization',
            name: 'Elsa Science AB',
          },
        }),
    ...(description && {
      description: description.description,
    }),
    articleBody: documentToHtmlString(JSON.parse(body.raw)),
    datePublished: published,
    ...(image && {
      image: {
        '@type': 'ImageObject',
        url: `https:${image.file.url}`,
        width: image.file.details.image.width,
        height: image.file.details.image.height,
      },
    }),
  }

  return (
    <Layout data={siteConfig} alternatePagePath={alternatePage.path} isAmp={false} isDark={false}>
      <SEO
        locale={locale}
        title={title}
        siteName={siteConfig.siteName}
        defaultDescription={siteConfig.defaultDescription}
        path={canonicalPath}
        alternatePage={{
          path: alternatePage.path,
          locale: alternatePage.context.locale,
        }}
        metadata={[
          {name: 'twitter:card', content: 'summary_large_image'},
          {name: 'twitter:title', content: title},
          {name: 'twitter:description', content: description?.description},
          {name: 'twitter:image', content: image && `https:${image.file.url}`},
          {name: 'twitter:image:alt', content: image?.title},
          {property: 'og:type', content: 'article'},
          {property: 'og:title', content: title},
          {property: 'og:description', content: description?.description},
          {property: 'og:image', content: image && `https:${image?.file?.url}`},
          {property: 'og:image:width', content: image?.file?.details?.image?.width?.toString()},
          {property: 'og:image:height', content: image?.file?.details?.image?.height?.toString()},
          {property: 'og:image:alt', content: image?.title},
          {property: 'og:article:published_time', content: published},
        ]}>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </SEO>
      <section className="section blog fit-content-to-bottom">
        <div className="container is-narrow">
          <div className="content">
            <ContentfulBlogPost data={blogPost} path={canonicalPath} isAmp={false} />
          </div>
          <footer className="columns">
            {previousBlogPost ? (
              <PaginationLink {...previousBlogPost} basePath={baseBlogPath} dir="previous" />
            ) : (
              <div className="column is-6" />
            )}
            {nextBlogPost && (
              <PaginationLink {...nextBlogPost} basePath={baseBlogPath} dir="next" />
            )}
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPost

interface PaginationLinkProps {
  published: string
  slug: string
  basePath: string
  title: string
  dir: 'next' | 'previous'
}

const PaginationLink: React.FC<PaginationLinkProps> = ({published, slug, basePath, title, dir}) => {
  const path = `${basePath}${published.split('T')[0].split('-').join('/')}/${slug}/`
  const isNext = dir === 'next'
  const chevronClass = {
    'has-small-chevron-left': !isNext,
    'has-small-chevron-right': isNext,
  }
  return (
    <Link
      to={path}
      className={cx('column', 'columns', 'is-6', 'm-2', 'is-align-items-center', {
        'has-text-right': isNext,
        'is-flex-direction-row-reverse': !isNext,
      })}>
      <div className="column ">
        <p className="has-text-gray-1 mb-1">{dir === 'previous' ? 'Previous post' : 'Next post'}</p>
        <p className="has-text-blue-3 mt-1 has-text-weight-bold">{title}</p>
      </div>
      <div className="blog-pagination circular-button column is-1">
        <p className={cx(chevronClass)} />
      </div>
    </Link>
  )
}
